import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy';
import { IActionContext } from '@msdyn365-commerce/core';
import { Buffer } from 'buffer';

export interface IBazaarVoiceResult {
    Title?: string;
    Rating: number;
    ReviewText?: string;
    SubmissionTime?: string;
    UserNickname?: string;
}

export interface IBazaarVoiceReviewsJson {
    TotalResults?: number;
    Results?: IBazaarVoiceResult[];
    Includes?: {
        Products?: {
            [key: string]: {
                Name: string;
                ReviewStatistics?: {
                    AverageOverallRating: number;
                };
            };
        };
    };
}

export interface ISecondaryRating {
    label: string;
    value?: number;
}

export interface IBazaarVoiceReviewSubmission {
    rating: number;
    email?: string;
    nickname?: string;
    title?: string;
    review?: string;
    recommendedString?: '' | 'yes' | 'no';
    agreedToTermsAndConditions?: boolean;
    secondaryRating: ISecondaryRating[];
    photos: File[];
    photoCaptions: string[];
    fingerprint?: string;
    errors: IBazaarVoiceFieldError[];
}

export interface IBazaarVoiceFieldError {
    Code: string;
    Field: string;
    Message: string;
}

export interface IBazaarVoiceField {
    Id: string;
    MinLength: number;
    MaxLength: number;
}

export interface IBazaarVoiceReviewSubmissionResponse {
    Data: {
        Fields: {
            [key: string]: IBazaarVoiceField;
        };
        FieldsOrder: string[];
    };
    HasErrors: any;
    Form: any;
    FormErrors: {
        FieldErrors?: {
            [key: string]: IBazaarVoiceFieldError;
        };
        FieldErrorsOrder?: string[];
    };
    TypicalHoursToPost: number | null;
    SubmissionId: string | null;
    Review: any;
    Locale: string;
    Errors: any[];
}

export async function getRatings(
    context: IActionContext,
    productId: string,
    pageSize: number,
    offset: number = 0
): Promise<IBazaarVoiceReviewsJson> {
    const query = new DataServiceQuery();
    const request = query.createDataServiceRequestForOperation(
        'GetBZReviews',
        false,
        '',
        'false',
        { bypassCache: 'get', returnEntity: '' },
        { ProductId: productId, PageSize: pageSize, Offset: offset }
    );

    const data = await request.execute<string>(context);
    const json = JSON.parse(data);

    return json;
}

export async function getReviewMetadata(context: IActionContext, productId: string): Promise<IBazaarVoiceReviewSubmissionResponse> {
    const query = new DataServiceQuery();
    const request = query.createDataServiceRequestForOperation(
        'GetBZMetadata',
        false,
        '',
        'false',
        { bypassCache: 'get', returnEntity: '' },
        { ProductId: productId }
    );

    const data = await request.execute<string>(context);
    const json = JSON.parse(data);

    return json;
}

export async function submitReview(
    context: IActionContext,
    productId: string,
    submission: IBazaarVoiceReviewSubmission
): Promise<IBazaarVoiceReviewSubmissionResponse> {
    const photos: any[] = [];
    for (let i = 0; i < submission.photos.length; i++) {
        const file: File = submission.photos[i];
        const caption: string = submission.photoCaptions[i];
        const arrayBuffer: ArrayBuffer = await file.arrayBuffer();
        const buffer: Buffer = Buffer.from(arrayBuffer);
        const base64data: string = buffer.toString('base64');

        photos.push({
            Name: file.name,
            Data: base64data,
            Caption: caption
        });
    }

    const query = new DataServiceQuery();
    const request = query.createDataServiceRequestForOperation(
        'PostBZReview',
        true,
        '',
        'false',
        { bypassCache: 'get', returnEntity: '' },
        {
            request: {
                ProductId: productId,
                Rating: submission.rating,
                Email: submission.email,
                Nickname: submission.nickname,
                Title: submission.title,
                Review: submission.review,
                Recommended: submission.recommendedString === undefined ? undefined : submission.recommendedString === 'yes',
                SecondaryRatings: submission.secondaryRating
                    .filter((rating: ISecondaryRating) => rating.value)
                    .map<any>((rating: ISecondaryRating) => {
                        return { Label: `rating_${rating.label}`, Rating: rating.value };
                    }),
                Photos: photos,
                Fingerprint: submission.fingerprint,
                AgreedToTermsAndConditions: submission.agreedToTermsAndConditions
            }
        }
    );
    const data = await request.execute<string>(context);
    const json = JSON.parse(data);

    return json;
}
