import React, { ChangeEvent } from 'react';
import {
    IBazaarVoiceReviewsJson,
    IBazaarVoiceResult,
    getRatings,
    getReviewMetadata,
    IBazaarVoiceReviewSubmission,
    submitReview,
    ISecondaryRating,
    IBazaarVoiceFieldError,
    IBazaarVoiceField
} from '../actions/review.action';
import { Modal, ModalBody, ModalFooter, ModalHeader, Waiting } from '@msdyn365-commerce-modules/utilities';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { IActionContext } from '@msdyn365-commerce/core';

const getRatingsPageSize = 10;

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ INTERFACES ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */
interface ICustomerReviewProps {
    title: string;
    rating: number;
    description: string;
    timestamp: string;
    nickname: string;
}

interface IStarProps {
    stars: number;
    level?: number;
}

interface IAverageRatingProps {
    stars?: number;
    reviewCount?: number;
}

interface IPagingControlProps {
    offset: number;
    totalReviews: number;
    setOffset: (value: number) => void;
}

interface IBazaarVoiceStarsProps {
    context: IActionContext;
    productId?: string;
    align?: 'left' | 'center' | 'right' | 'fit';
    size?: '' | 'small';
    withCount?: boolean;
    rating?: number;
}

interface ILeaveReviewModalProps {
    context: IActionContext;
    isOpen: boolean;
    toggle: () => void;
    productId: string;
    productName?: string;
}

interface IBazaarVoiceReviewOverviewProps {
    context: IActionContext;
    productId?: string;
    productName?: string;
}

const formatDate = (date: Date | string) => {
    date = new Date(date);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() < 12 ? 'am' : 'pm';

    const dateString: string = `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
    return dateString;
};

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ PRIVATE COMPONENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

/*
 * Renders a single star based on the rating and star level
 *
 * Resulting star is either filled, left-partially-filled, or empty
 */
const Star = (props: IStarProps) => {
    const stars: number = props.stars ?? 0;
    const level: number = props.level ?? 0;

    // round to the nearest tenth
    const tenths: number = Math.round((stars + 1 - level) * 10);
    // full star if more than 10/10 of star
    const isFullStar: boolean = tenths >= 10;
    // empty star if less than 0/10 of star
    const isEmptyStar: boolean = tenths <= 0;

    let starClass = 'nss-review__star';
    if (isFullStar) {
        starClass += ' nss-review__full-star';
    } else if (isEmptyStar) {
        starClass += ' nss-review__empty-star';
    } else {
        starClass += ` nss-review__${tenths}-tenth-star`;
    }

    return <div className={starClass}></div>;
};

/*
 * Renders 5 stars for a five star rating, partial stars allowed
 */
export const StarRating = (props: IStarProps) => {
    const stars: number = props.stars ?? 0;

    return (
        <>
            <Star stars={stars} level={1} />
            <Star stars={stars} level={2} />
            <Star stars={stars} level={3} />
            <Star stars={stars} level={4} />
            <Star stars={stars} level={5} />
        </>
    );
};

/*
 * Renders the five star rating with the review count
 */
export const AverageRating = (props: IAverageRatingProps) => {
    const stars: number = props.stars ?? 0;
    const reviewCount: number = props.reviewCount ?? 0;
    const roundedStars: number = Math.round(stars * 10) / 10;

    return (
        <div className='nss-review__average-rating'>
            <div className='nss-review__average-rating-stars' title={`${roundedStars} stars`}>
                <StarRating stars={stars} />
            </div>
            <div className='nss-review__rating-count'>from {reviewCount} reviews</div>
        </div>
    );
};

/*
 * Renders a single customer review for the review overview
 */
const CustomerReview = (props: ICustomerReviewProps) => {
    const { title, rating, description, timestamp, nickname } = props;

    return (
        <>
            <div className='nss-review__customer-review'>
                <div className='nss-review__customer-review-header'>
                    <div className='nss-review__customer-review-title'>{title}</div>
                    <div className='nss-review__customer-review-stars'>
                        <StarRating stars={rating} />
                    </div>
                </div>
                <div className='nss-review__customer-review-body'>{description}</div>
                <div className='nss-review__customer-review-footer'>
                    <div className='nss-review__customer-review-timestamp'>{timestamp}</div>
                    <div className='nss-review__customer-review-nickname'>{nickname}</div>
                </div>
            </div>
        </>
    );
};

const PagingControl = (props: IPagingControlProps) => {
    const offset: number = props.offset;
    const totalReviews: number = props.totalReviews;
    const setOffset: (value: number) => void = (value: number) => {
        const minOffset = 0;
        const maxOffset = (totalPages - 1) * getRatingsPageSize;

        // limit the offset to the desired range
        value = value < minOffset ? minOffset : value;
        value = value > maxOffset ? maxOffset : value;

        // call the passed function
        props.setOffset(value);
    };

    const index: number = offset / getRatingsPageSize;
    const totalPages: number = Math.ceil(totalReviews / getRatingsPageSize);
    const pageRange: number[] = [];

    // start two before index
    let startIndex: number = index - 2;

    // bounds check start index
    startIndex = startIndex < 0 ? 0 : startIndex;

    // end four after start
    let endIndex: number = startIndex + 4;

    // bounds check end index
    endIndex = endIndex >= totalPages ? totalPages - 1 : endIndex;

    // special case where we are on one of the last few pages
    startIndex = startIndex > endIndex - 4 ? endIndex - 4 : startIndex;
    startIndex = startIndex < 0 ? 0 : startIndex;

    // fill page range
    for (let i = startIndex; i <= endIndex; i++) {
        pageRange.push(i);
    }

    return (
        <ul className='nss-review__customer-rating-pager'>
            <li onClick={() => setOffset(0)} title='First Page'>
                &lt;&lt;
            </li>
            <li onClick={() => setOffset(offset - getRatingsPageSize)} title='Previous Page'>
                &lt;
            </li>
            {pageRange.map((value: number) => (
                <li
                    key={value}
                    className={value === index ? 'nss-review__customer-rating-pager-selected-page' : ''}
                    onClick={() => setOffset(value * getRatingsPageSize)}
                >
                    {value + 1}
                </li>
            ))}
            <li onClick={() => setOffset(offset + getRatingsPageSize)} title='Next Page'>
                &gt;
            </li>
            <li onClick={() => setOffset((totalPages - 1) * getRatingsPageSize)} title='Final Page'>
                &gt;&gt;
            </li>
        </ul>
    );
};

const FormRating = (props: any) => {
    const rating: number = props.rating;
    const setRating: (rating: number) => void = props.setRating;

    return (
        <div className='nss-review__form-rating'>
            <div onClick={() => setRating(1)}>
                <Star stars={rating} level={1} />
            </div>
            <div onClick={() => setRating(2)}>
                <Star stars={rating} level={2} />
            </div>
            <div onClick={() => setRating(3)}>
                <Star stars={rating} level={3} />
            </div>
            <div onClick={() => setRating(4)}>
                <Star stars={rating} level={4} />
            </div>
            <div onClick={() => setRating(5)}>
                <Star stars={rating} level={5} />
            </div>
        </div>
    );
};

const FieldError = (props: any) => {
    const fieldError: IBazaarVoiceFieldError | undefined = props.fieldError;

    return fieldError ? <div className='nss-review__field-error'>{fieldError.Message}</div> : null;
};

const getCharCountText = (textEntry: string | undefined, fieldMetadata: IBazaarVoiceField | undefined) => {
    if (fieldMetadata === undefined) {
        return '';
    }

    textEntry = textEntry ?? '';
    const length: number = textEntry.length;
    const minLength: number = fieldMetadata.MinLength;
    const maxLength: number = fieldMetadata.MaxLength;

    const charsToMin: number = minLength - length;
    const charsLeft: number = maxLength - length;

    if (length === 0 && minLength !== 0) {
        return `Enter at least ${minLength} characters`;
    }

    if (charsToMin > 0) {
        return `Enter ${charsToMin} more characters`;
    }

    if (charsLeft >= 0) {
        return `${charsLeft} characters remaining`;
    }

    return `Character limit exceeded by ${-charsLeft}`;
};

const LeaveReviewModal = (props: ILeaveReviewModalProps) => {
    const context: IActionContext = props.context;
    const isOpen: boolean = props.isOpen;
    const toggle: () => void = props.toggle;
    const productId: string = props.productId;
    const productName: string = props.productName || '';

    const [formData, setFormData] = React.useState<IBazaarVoiceReviewSubmission>({
        rating: 0,
        secondaryRating: [],
        photos: [],
        photoCaptions: [],
        errors: []
    });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [fieldMetadata, setFieldMetadata] = React.useState<{ [key: string]: IBazaarVoiceField }>({});

    const clearForm = () => {
        setFormData({
            rating: 0,
            secondaryRating: [],
            photos: [],
            photoCaptions: [],
            errors: []
        });
    };

    React.useEffect(() => {
        clearForm();
        setIsLoading(true);
        getReviewMetadata(context, productId)
            .then(metadata => {
                const labels: ISecondaryRating[] = metadata.Data.FieldsOrder.filter(key => key.includes('rating_')).map(key => {
                    return {
                        label: key.replace('rating_', '')
                    };
                });
                formData.secondaryRating = labels;
                const fields: { [key: string]: IBazaarVoiceField } = metadata.Data.Fields;

                setFormData({ ...formData });
                setFieldMetadata(fields);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, [productId]);

    const setAgreedToTermsAndConditions = () => {
        formData.agreedToTermsAndConditions = !formData.agreedToTermsAndConditions;
        removeError('agreedtotermsandconditions');
        setFormData({ ...formData });
    };

    const setRating = (rating: number) => {
        formData.rating = rating;
        removeError('rating');
        setFormData({ ...formData });
    };

    const setEmail = (event: ChangeEvent<HTMLInputElement>) => {
        formData.email = event.target.value;
        formData.email = truncateText(formData.email, 'useremail');
        removeError('useremail');
        setFormData({ ...formData });
    };

    const setNickname = (event: ChangeEvent<HTMLInputElement>) => {
        formData.nickname = event.target.value;
        formData.nickname = truncateText(formData.nickname, 'usernickname');
        removeError('usernickname');
        setFormData({ ...formData });
    };

    const setTitle = (event: ChangeEvent<HTMLInputElement>) => {
        formData.title = event.target.value;
        formData.title = truncateText(formData.title, 'title');
        removeError('title');
        setFormData({ ...formData });
    };

    const setReview = (event: ChangeEvent<HTMLTextAreaElement>) => {
        formData.review = event.target.value;
        formData.review = truncateText(formData.review, 'reviewtext');
        removeError('reviewtext');
        setFormData({ ...formData });
    };

    const setRecommended = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === '' || event.target.value === 'yes' || event.target.value === 'no') {
            formData.recommendedString = event.target.value;
            setFormData({ ...formData });
        }
    };

    const setSecondaryRating = (i: number, rating: number) => {
        formData.secondaryRating[i].value = rating;
        setFormData({ ...formData });
    };

    const addPhoto = () => {
        removeError('photo');
        const photoInput: HTMLInputElement = document.createElement('input');
        photoInput.style.visibility = 'hidden';
        photoInput.setAttribute('type', 'file');
        photoInput.setAttribute('multiple', 'multiple');
        photoInput.setAttribute('accept', 'image/*');
        photoInput.onchange = () => {
            if (photoInput.files) {
                for (let i = 0; i < photoInput.files.length; i++) {
                    const photo: File = photoInput.files[i];
                    let validUpload: boolean = true;

                    // check for error in file type
                    if (!photo.type.startsWith('image')) {
                        const message: string = `Cannot upload photo ${photo.name} because it is an unsupported file type.`;
                        formData.errors.push({
                            Code: 'ERROR_FORM_PHOTO_FILE_TYPE',
                            Field: 'photo',
                            Message: message
                        });
                        validUpload = false;
                    }

                    // check for file size limit
                    if (photo.size > 10 * 1048576) {
                        const message: string = `Cannot upload photo ${photo.name}  exceeds maximum upload size of 10MB`;
                        formData.errors.push({
                            Code: 'ERROR_FORM_MAX_PHOTO_SIZE_EXCEEDED',
                            Field: 'photo',
                            Message: message
                        });
                        validUpload = false;
                    }

                    // check for upload count limit
                    if (formData.photos.length >= 6) {
                        formData.errors.push({
                            Code: 'ERROR_FORM_MAX_PHOTO_COUNT_EXCEEDED',
                            Field: 'photo',
                            Message: 'Cannot upload any more photos, maximum number of photos (6) reached.'
                        });
                        validUpload = false;
                    }

                    // add to list if all checks pass
                    if (validUpload) {
                        formData.photos.push(photo);
                    }
                }

                setFormData({ ...formData });
            }
        };
        document.body.appendChild(photoInput);
        photoInput.click();
        document.body.removeChild(photoInput);
    };

    const removePhoto = (index: number) => {
        removeError('photo');
        formData.photos = formData.photos.filter((file, i) => index !== i);
        formData.photoCaptions = formData.photoCaptions.filter((caption, i) => index !== i);
        setFormData({ ...formData });
    };

    const setPhotoCaption = (event: ChangeEvent<HTMLTextAreaElement>, index: number) => {
        removeError('photo');
        formData.photoCaptions[index] = event.target.value;
        setFormData({ ...formData });
    };

    const submitForm = async () => {
        setIsLoading(true);

        try {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            formData.fingerprint = visitorId;
            formData.errors = getClientErrors();

            if (formData.errors.length > 0) {
                // errors determined exist client side
                alert('Form contains errors, please correct errors and try again.');
                console.error(formData.errors);
                setFormData({ ...formData });
                setIsLoading(false);
                return;
            }

            // send request to proxy
            const response = await submitReview(context, productId, formData);

            if ('message' in response) {
                // error response
                const message: string = typeof response['message'] === 'string' ? response['message'] : '';

                formData.errors.push({
                    Code: 'INTERNAL_SERVER_ERROR',
                    Field: 'general',
                    Message: message
                });
                alert('Form contains errors, please correct errors and try again.');
                console.error(formData.errors);
                setFormData({ ...formData });
                setIsLoading(false);
                return;
            }

            // check for server errors
            for (const error of response.Errors) {
                formData.errors.push({ ...error, Field: 'general' });
            }
            if (response.FormErrors.FieldErrors && response.FormErrors.FieldErrorsOrder) {
                for (const field of response.FormErrors.FieldErrorsOrder) {
                    formData.errors.push(response.FormErrors.FieldErrors[field]);
                }
            }

            if (formData.errors.length > 0) {
                // errors determined exist server side
                alert('Form contains errors, please correct errors and try again.');
                console.error(formData.errors);
                setFormData({ ...formData });
                setIsLoading(false);
                return;
            }

            if (response.SubmissionId) {
                // form was sumbitted without errors
                const message: string = `Review submitted! Please allow ${response.TypicalHoursToPost} hours for your review to be posted.`;
                alert(message);
                clearForm();
                toggle();
            } else {
                // something else happened here, print some debugging
                console.error('Uncaught error submitting review', formData, response);
                alert('Something went wrong, Please try again later.');
            }
        } catch (exception) {
            // something else happened here, print some debugging
            console.error('Uncaught error submitting review', formData, exception);
            alert('Something went wrong, Please try again later.');
        }

        setIsLoading(false);
    };

    const getClientErrors = (): IBazaarVoiceFieldError[] => {
        const errors: IBazaarVoiceFieldError[] = [];

        if (!formData.agreedToTermsAndConditions) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'agreedtotermsandconditions',
                Message: 'You must agree to terms and conditions to submit a review.'
            });
        }

        if (!formData.rating) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'rating',
                Message: 'Rating is required.'
            });
        }

        if (!formData.email) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'useremail',
                Message: 'Email is required.'
            });
        }

        if (!formData.nickname) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'usernickname',
                Message: 'Nickname is required.'
            });
        }

        if (!formData.title) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'title',
                Message: 'Title is required.'
            });
        }

        if (!formData.review) {
            errors.push({
                Code: 'ERROR_FORM_REQUIRES_TRUE',
                Field: 'reviewtext',
                Message: 'Review Text is required.'
            });
        }

        return errors;
    };

    const getError = (field: string): IBazaarVoiceFieldError | undefined => {
        return formData.errors.find(value => value.Field.toLowerCase().includes(field.toLowerCase()));
    };

    const removeError = (field: string): void => {
        formData.errors = formData.errors.filter(
            (value: IBazaarVoiceFieldError) => !value.Field.toLowerCase().includes(field.toLowerCase())
        );
    };

    const truncateText = (text: string, field: string): string => {
        if (text.length > fieldMetadata[field]?.MaxLength) {
            text = text.substring(0, fieldMetadata[field].MaxLength);
        }

        return text;
    };

    return (
        <Modal className='nss-review__form-wrapper' autoFocus fade={false} applicationNode='renderPage' isOpen={isOpen} toggle={toggle}>
            <Loading isLoading={isLoading} />
            <ModalHeader toggle={toggle}>Write Review</ModalHeader>
            <ModalBody>
                <div className='nss-review__form-input'>
                    <FieldError fieldError={getError('general')} />
                </div>
                <div className='nss-review__form-input'>
                    <label className='nss-review__form-description'>{productName}</label>
                </div>
                <div className='nss-review__form-input'>
                    <label>Rating</label>
                    <FormRating rating={formData.rating} setRating={setRating} />
                    <FieldError fieldError={getError('rating')} />
                </div>
                <div className='nss-review__form-input'>
                    <div>
                        <label>Email</label>
                        <span>{getCharCountText(formData.email, fieldMetadata['useremail'])}</span>
                    </div>
                    <input value={formData.email || ''} onChange={setEmail} type='email' />
                    <FieldError fieldError={getError('useremail')} />
                </div>
                <div className='nss-review__form-input'>
                    <div>
                        <label>Nickname</label>
                        <span>{getCharCountText(formData.nickname, fieldMetadata['usernickname'])}</span>
                    </div>
                    <input value={formData.nickname || ''} onChange={setNickname} />
                    <FieldError fieldError={getError('usernickname')} />
                </div>
                <div className='nss-review__form-input'>
                    <div>
                        <label>Title</label>
                        <span>{getCharCountText(formData.title, fieldMetadata['title'])}</span>
                    </div>
                    <input value={formData.title || ''} onChange={setTitle} />
                    <FieldError fieldError={getError('title')} />
                </div>
                <div className='nss-review__form-input'>
                    <div>
                        <label>Review</label>
                        <span>{getCharCountText(formData.review, fieldMetadata['reviewtext'])}</span>
                    </div>
                    <textarea onChange={setReview} value={formData.review || ''}></textarea>
                    <FieldError fieldError={getError('reviewtext')} />
                </div>
                <div className='nss-review__form-input'>
                    <label>Recommended</label>
                    <select onChange={setRecommended} value={formData.recommendedString || ''}>
                        <option value=''></option>
                        <option value='yes'>Yes</option>
                        <option value='no'>No</option>
                    </select>
                    <FieldError fieldError={getError('isrecommended')} />
                </div>
                {formData.secondaryRating.map((secondaryRating, index) => (
                    <div className='nss-review__form-input' key={index}>
                        <label>{secondaryRating.label}</label>
                        <FormRating rating={secondaryRating.value} setRating={(rating: number) => setSecondaryRating(index, rating)} />
                    </div>
                ))}
                <div className='nss-review__form-input'>
                    <label>Photos</label>
                    <div className='nss-review__form-image-wrap'>
                        {formData.photos.map((photo: File, index: number) => (
                            <div key={index} className='nss-review__form-image-container'>
                                <img src={URL.createObjectURL(photo)} />
                                <div className='nss-review__form-image-controls'>
                                    <textarea
                                        placeholder='Image caption'
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setPhotoCaption(event, index)}
                                        value={formData.photoCaptions[index]}
                                    ></textarea>
                                    <button title='Remove Photo' onClick={() => removePhoto(index)}>
                                        Remove Photo
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <FieldError fieldError={getError('photo')} />
                    <button onClick={addPhoto}>Add Photo</button>
                </div>
                <div className='nss-review__form-input'>
                    <label>Terms and Conditions</label>
                    <a href='https://www.bazaarvoice.com/legal/online-terms/' target='_blank' rel='noopener noreferrer'>
                        View Terms and Conditions
                    </a>
                    <span>
                        <input checked={formData.agreedToTermsAndConditions} onChange={setAgreedToTermsAndConditions} type='checkbox' />I
                        agree to the terms and conditions
                    </span>
                    <FieldError fieldError={getError('agreedtotermsandconditions')} />
                </div>
            </ModalBody>
            <ModalFooter>
                <button onClick={submitForm} className='nss-review__form-submit'>
                    Submit
                </button>
            </ModalFooter>
        </Modal>
    );
};

const Loading = (props: any) => {
    const isLoading = props.isLoading;

    if (!isLoading) {
        return null;
    }

    return (
        <div className='nss-review__loading-overlay'>
            <div>
                <Waiting className='msc-waiting-circular msc-waiting-lg' />
                <span>Loading...</span>
            </div>
        </div>
    );
};

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ EXPORTED COMPONENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */
export const BazaarVoiceStars = (props: IBazaarVoiceStarsProps) => {
    const context: IActionContext = props.context;
    const productId: string = props.productId ?? '';
    const align: string = props.align ?? 'center';
    const size: string = props.size ?? '';
    const withCount: boolean = props.withCount ?? false;
    const rating: number = props.rating ?? 0;

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<any>(null);
    const [stars, setStars] = React.useState<number>(rating);
    const [reviewCount, setReviewCount] = React.useState<number>();

    React.useEffect(() => {
        getRatings(context, productId, getRatingsPageSize)
            .then((json: IBazaarVoiceReviewsJson) => {
                const stars: number = json?.Includes?.Products?.[productId]?.ReviewStatistics?.AverageOverallRating ?? 0;
                const reviewCount: number | undefined = json?.TotalResults;

                setStars(stars);
                setReviewCount(reviewCount);
                setIsLoading(false);
                setError(null);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                setError(error);
            });
    }, [productId, rating]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading review data</div>;
    }

    if (withCount) {
        if (!reviewCount) {
            return (
                <div className='nss-review__average-rating'>
                    <a className='nss-review__no-reviews-yet'>No reviews available, be the first to review!</a>
                </div>
            );
        }

        return <AverageRating stars={stars} reviewCount={reviewCount} />;
    }

    if (!reviewCount || !stars) {
        return null;
    }

    return (
        <div className={`nss-review__average-rating ${align} ${size}`}>
            <div className='nss-review__average-rating-stars' title={`${Math.round(stars * 10) / 10} stars`}>
                <StarRating stars={stars} />
            </div>
        </div>
    );
};

export const BazaarVoiceReviewOverview = (props: IBazaarVoiceReviewOverviewProps) => {
    const context: IActionContext = props.context;
    const productId: string = props.productId ?? '';
    const productName: string = props.productName ?? '';

    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [error, setError] = React.useState<any>(null);
    const [stars, setStars] = React.useState<number>();
    const [reviewCount, setReviewCount] = React.useState<number>();
    const [customerReviews, setCustomerReviews] = React.useState<ICustomerReviewProps[]>([]);
    const [showReviews, setShowReviews] = React.useState<boolean>(false);
    const [offset, setOffset] = React.useState<number>(0);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const processApiData = (json: IBazaarVoiceReviewsJson) => {
        const stars: number = json?.Includes?.Products?.[productId]?.ReviewStatistics?.AverageOverallRating ?? 0;
        const reviewCount: number = json?.TotalResults ?? 0;
        const customerReviews: ICustomerReviewProps[] =
            json?.Results?.map((result: IBazaarVoiceResult) => {
                return {
                    title: result.Title ?? '',
                    rating: result.Rating ?? '',
                    description: result.ReviewText ?? '',
                    timestamp: result.SubmissionTime ? formatDate(result.SubmissionTime) : '',
                    nickname: result.UserNickname ?? ''
                };
            }) ?? [];

        setStars(stars);
        setReviewCount(reviewCount);
        setCustomerReviews(customerReviews);
        setIsLoaded(true);
        setError(null);
    };

    React.useEffect(() => {
        setOffset(0);
        getRatings(context, productId, getRatingsPageSize)
            .then(processApiData)
            .catch(error => {
                console.error(error);
                setIsLoaded(true);
                setError(error);
            });
    }, [productId]);

    React.useEffect(() => {
        getRatings(context, productId, getRatingsPageSize, offset)
            .then(processApiData)
            .catch(error => {
                console.error(error);
                setIsLoaded(true);
                setError(error);
            });
    }, [offset]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>An error occurred loading review data</div>;
    }

    return (
        <div className='nss-review__review-overview'>
            <h3>Customer Reviews</h3>
            <div className='nss-review__review-overview-header'>
                <AverageRating stars={stars} reviewCount={reviewCount} />
                <a className='nss-review__leave-review-link' onClick={() => setIsModalOpen(true)}>
                    review
                </a>
                <LeaveReviewModal
                    context={context}
                    productId={productId}
                    productName={productName}
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(!isModalOpen)}
                />
            </div>
            <div className='nss-review__review-overview-section'>
                <a className='nss-review__show-hide-reviews-link' onClick={() => setShowReviews(!showReviews)}>
                    {showReviews ? 'hide reviews' : 'show reviews'}
                </a>
            </div>
            {showReviews && (
                <>
                    <div className='nss-review__customer-reviews'>
                        {customerReviews?.map((review: ICustomerReviewProps, index: number) => (
                            <CustomerReview key={index} {...review} />
                        ))}
                    </div>
                    <div className='nss-review__review-overview-section'>
                        {customerReviews !== undefined && reviewCount !== undefined && reviewCount > customerReviews.length && (
                            <PagingControl offset={offset} totalReviews={reviewCount} setOffset={setOffset} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
