const binding = { modules: {}, dataActions: {} };

    (binding.modules['spacer'] = {
        c: () => require('@msdyn365-commerce-modules/spacer/dist/lib/modules/spacer/spacer'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'spacer',
        p: 'spacer',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/spacer/dist/lib/modules/spacer'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|newsunshine-b2c|views|spacer'] = {
                c: () => require('partner/themes/newsunshine-b2c/views/spacer.view.tsx'),
                cn: '__local__-__local__-spacer'
            };
viewDictionary['__local__|__local__|themes|newsunshine|views|spacer'] = {
                c: () => require('partner/themes/newsunshine/views/spacer.view.tsx'),
                cn: '__local__-__local__-spacer'
            };
viewDictionary['@msdyn365-commerce-modules|spacer|modules|spacer|spacer'] = {
                c: () => require('@msdyn365-commerce-modules/spacer/dist/lib/modules/spacer/spacer.view.js'),
                cn: '@msdyn365-commerce-modules-spacer-spacer'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };